export default class t {
  constructor(t) {
    this.opts = t;
  }
  setCore(t) {
    this.core = t;
    return this;
  }
  install() {}
  uninstall() {}
}
