import e from '../core/Plugin';
import t from '../utils/classSet';
export default class s extends e {
  constructor(e) {
    super(e);
    this.messages = new Map();
    this.defaultContainer = document.createElement('div');
    this.opts = Object.assign({}, {container: (e, t) => this.defaultContainer}, e);
    this.elementIgnoredHandler = this.onElementIgnored.bind(this);
    this.fieldAddedHandler = this.onFieldAdded.bind(this);
    this.fieldRemovedHandler = this.onFieldRemoved.bind(this);
    this.validatorValidatedHandler = this.onValidatorValidated.bind(this);
    this.validatorNotValidatedHandler = this.onValidatorNotValidated.bind(this);
  }
  static getClosestContainer(e, t, s) {
    let i = e;
    while (i) {
      if (i === t) {
        break;
      }
      i = i.parentElement;
      if (s.test(i.className)) {
        break;
      }
    }
    return i;
  }
  install() {
    this.core.getFormElement().appendChild(this.defaultContainer);
    this.core
      .on('core.element.ignored', this.elementIgnoredHandler)
      .on('core.field.added', this.fieldAddedHandler)
      .on('core.field.removed', this.fieldRemovedHandler)
      .on('core.validator.validated', this.validatorValidatedHandler)
      .on('core.validator.notvalidated', this.validatorNotValidatedHandler);
  }
  uninstall() {
    this.core.getFormElement().removeChild(this.defaultContainer);
    this.messages.forEach((e) => e.parentNode.removeChild(e));
    this.messages.clear();
    this.core
      .off('core.element.ignored', this.elementIgnoredHandler)
      .off('core.field.added', this.fieldAddedHandler)
      .off('core.field.removed', this.fieldRemovedHandler)
      .off('core.validator.validated', this.validatorValidatedHandler)
      .off('core.validator.notvalidated', this.validatorNotValidatedHandler);
  }
  onFieldAdded(e) {
    const t = e.elements;
    if (t) {
      t.forEach((e) => {
        const t = this.messages.get(e);
        if (t) {
          t.parentNode.removeChild(t);
          this.messages.delete(e);
        }
      });
      this.prepareFieldContainer(e.field, t);
    }
  }
  onFieldRemoved(e) {
    if (!e.elements.length || !e.field) {
      return;
    }
    const t = e.elements[0].getAttribute('type');
    const s = 'radio' === t || 'checkbox' === t ? [e.elements[0]] : e.elements;
    s.forEach((e) => {
      if (this.messages.has(e)) {
        const t = this.messages.get(e);
        t.parentNode.removeChild(t);
        this.messages.delete(e);
      }
    });
  }
  prepareFieldContainer(e, t) {
    if (t.length) {
      const s = t[0].getAttribute('type');
      if ('radio' === s || 'checkbox' === s) {
        this.prepareElementContainer(e, t[0], t);
      } else {
        t.forEach((s) => this.prepareElementContainer(e, s, t));
      }
    }
  }
  prepareElementContainer(e, s, i) {
    let a;
    switch (true) {
      case 'string' === typeof this.opts.container:
        let t = this.opts.container;
        t = '#' === t.charAt(0) ? `[id="${t.substring(1)}"]` : t;
        a = this.core.getFormElement().querySelector(t);
        break;
      default:
        a = this.opts.container(e, s);
        break;
    }
    const l = document.createElement('div');
    a.appendChild(l);
    t(l, {'fv-plugins-message-container': true});
    this.core.emit('plugins.message.placed', {element: s, elements: i, field: e, messageElement: l});
    this.messages.set(s, l);
  }
  getMessage(e) {
    return typeof e.message === 'string' ? e.message : e.message[this.core.getLocale()];
  }
  onValidatorValidated(e) {
    const s = e.elements;
    const i = e.element.getAttribute('type');
    const a = 'radio' === i || 'checkbox' === i ? s[0] : e.element;
    if (this.messages.has(a)) {
      const s = this.messages.get(a);
      const i = s.querySelector(`[data-field="${e.field}"][data-validator="${e.validator}"]`);
      if (!i && !e.result.valid) {
        const i = document.createElement('div');
        i.innerHTML = this.getMessage(e.result);
        i.setAttribute('data-field', e.field);
        i.setAttribute('data-validator', e.validator);
        if (this.opts.clazz) {
          t(i, {[this.opts.clazz]: true});
        }
        s.appendChild(i);
        this.core.emit('plugins.message.displayed', {
          element: e.element,
          field: e.field,
          message: e.result.message,
          messageElement: i,
          meta: e.result.meta,
          validator: e.validator,
        });
      } else if (i && !e.result.valid) {
        i.innerHTML = this.getMessage(e.result);
        this.core.emit('plugins.message.displayed', {
          element: e.element,
          field: e.field,
          message: e.result.message,
          messageElement: i,
          meta: e.result.meta,
          validator: e.validator,
        });
      } else if (i && e.result.valid) {
        s.removeChild(i);
      }
    }
  }
  onValidatorNotValidated(e) {
    const t = e.elements;
    const s = e.element.getAttribute('type');
    const i = 'radio' === s || 'checkbox' === s ? t[0] : e.element;
    if (this.messages.has(i)) {
      const t = this.messages.get(i);
      const s = t.querySelector(`[data-field="${e.field}"][data-validator="${e.validator}"]`);
      if (s) {
        t.removeChild(s);
      }
    }
  }
  onElementIgnored(e) {
    const t = e.elements;
    const s = e.element.getAttribute('type');
    const i = 'radio' === s || 'checkbox' === s ? t[0] : e.element;
    if (this.messages.has(i)) {
      const t = this.messages.get(i);
      const s = [].slice.call(t.querySelectorAll(`[data-field="${e.field}"]`));
      s.forEach((e) => {
        t.removeChild(e);
      });
    }
  }
}
